
import NavigationTop from "@/components/shared/layout/NavigationTop.vue";
import NavigationLeft from "@/components/shared/layout/NavigationLeft.vue";
import { Component, Vue } from "vue-property-decorator";
import { eventHub } from "@/router";
import { ValidationError } from "@fundraisingbureauet/admin-typescript-client/models/ValidationError";

export interface Toast {
  id: number;
  type: "warning" | "error" | "success";
  message: string;
  fadeOut: boolean;
}

@Component({
  components: { NavigationTop, NavigationLeft },
})
export default class Navigation extends Vue {
  toasts: Toast[] = [];

  //  Initialise
  mounted() {
    eventHub.$on("httpError", (error: any) => {
      //  Log
      console.log(`error event: ${error}`);

      //  Toast
      if (error.status == 500) {
        this.errorToast(error.response.message);
      } else if (error.status == 400) {
        const validationErrors = error.response.validationErrors;
        validationErrors.forEach(
          (error: ValidationError) => this.errorToast(`${error.message}`)
          //  this.warningToast(`${error.fieldName}: ${error.message}`)
        );
      }
    });
    eventHub.$on("errorToast", (message: string) => {
      this.errorToast(message);
    });
    eventHub.$on("warningToast", (message: string) => {
      this.warningToast(message);
    });
    eventHub.$on("successToast", (message: string) => {
      this.successToast(message);
    });
  }

  //  Functions
  errorToast(message: string) {
    this.createToast(message, "error");
  }

  warningToast(message: string) {
    this.createToast(message, "warning");
  }

  successToast(message: string) {
    this.createToast(message, "success");
  }

  createToast(message: string, type: "warning" | "error" | "success") {
    const id: number = Math.random();
    const toast: Toast = {
      id: id,
      message: message,
      type: type,
      fadeOut: false,
    };
    this.toasts.push(toast);
    setTimeout(() => {
      toast.fadeOut = true;
      setTimeout(() => {
        this.removeToast(id);
      }, 600);
    }, 7_500);
  }

  removeToast(id: number) {
    const index = this.toasts.findIndex((value) => value.id === id);
    this.toasts.splice(index, 1);
  }

  //  Log out
  logout() {
    localStorage.removeItem("token");

    //  Log
    console.log("Router push - logout");

    this.$router.push({ name: "Login" });
  }
}
