<template>
  <div></div>
</template>

<script type="ts">

import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class NavigationTop extends Vue {

}
</script>
