
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NavigationLeft extends Vue {
  logout() {
    localStorage.removeItem("token");

    //  Log
    console.log("Router push - logout");

    this.$router.push({ name: "Login" });
  }

  get section(): string {
    let sectionName = this.$route.path.split("/")[2];

    if (this.$route.path.split("/").length > 3) {
      sectionName = sectionName + "/" + this.$route.path.split("/")[3];
    }

    return sectionName;
  }
}
