var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('navigation-left'),_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"toast-container"},_vm._l((_vm.toasts),function(toast){return _c('div',{key:toast.id,staticClass:"toast",class:{
            'success-toast': toast.type === 'success',
            'error-toast': toast.type === 'error',
            'warning-toast': toast.type === 'warning',
            'fade-out': toast.fadeOut,
            'fade-in': !toast.fadeOut,
          }},[_vm._v(" "+_vm._s(toast.message)+" ")])}),0),_c('div',{staticClass:"content"},[_c('router-view')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }