var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav-left",attrs:{"role":"menu"}},[_c('div',{staticClass:"nav-wrap"},[_vm._m(0),_c('nav',[_c('div',{attrs:{"id":"of_side_menu"}},[_c('router-link',{attrs:{"to":{ name: 'Invoicing' }}},[_c('h2',{class:{
                'head-invoicing': _vm.section === 'invoicing',
                'menu-invoicing': _vm.section !== 'invoicing',
              }},[_vm._v(" Invoices ")])]),_c('router-link',{staticClass:"sub-menu",attrs:{"to":{ name: 'InvoicingLog' }}},[_c('h2',{class:{
                'head-invoicing-log': _vm.section === 'invoicing/log',
                'menu-invoicing-log': _vm.section !== 'invoicing/log',
              }},[_vm._v(" Log ")])]),_c('router-link',{staticClass:"sub-menu",attrs:{"to":{ name: 'Uninvoiced' }}},[_c('h2',{class:{
                'head-uninvoiced': _vm.section === 'invoicing/uninvoiced',
                'menu-uninvoiced': _vm.section !== 'invoicing/uninvoiced',
              }},[_vm._v(" Uninvoiced ")])]),_c('router-link',{attrs:{"to":{ name: 'Notices' }}},[_c('h2',{class:{
                'head-bustomers': _vm.section === 'notices',
                'menu-customers': _vm.section !== 'notices',
              }},[_vm._v(" Notices ")])]),_c('router-link',{attrs:{"to":{ name: 'CustomerList' }}},[_c('h2',{class:{
                'head-bustomers': _vm.section === 'customers',
                'menu-customers': _vm.section !== 'customers',
              }},[_vm._v(" Customers ")])]),_c('router-link',{attrs:{"to":{ name: 'Merchants' }}},[_c('h2',{class:{
                'head-merchants': _vm.section === 'merchants',
                'menu-merchants': _vm.section !== 'merchants',
              }},[_vm._v(" Merchants ")])]),_c('router-link',{attrs:{"to":{ name: 'Products' }}},[_c('h2',{class:{
                'head-products': _vm.section === 'products',
                'menu-products': _vm.section !== 'products',
              }},[_vm._v(" Products ")])]),_c('router-link',{attrs:{"to":{ name: 'GatewayList' }}},[_c('h2',{class:{
                'head-gateways': _vm.section === 'gateways',
                'menu-gateways': _vm.section !== 'gateways',
              }},[_vm._v(" Gateways ")])]),_c('router-link',{staticClass:"sub-menu",attrs:{"to":{ name: 'EndpointList' }}},[_c('h2',{class:{
                'head-endpoints': _vm.section === 'endpoints',
                'menu-endpoints': _vm.section !== 'endpoints',
              }},[_vm._v(" Endpoints ")])]),_c('router-link',{attrs:{"to":{ name: 'AgenciesList' }}},[_c('h2',{class:{
                'head-agencies': _vm.section === 'agencies',
                'menu-agencies': _vm.section !== 'agencies',
              }},[_vm._v(" Onboarding agencies ")])]),_c('router-link',{attrs:{"to":{ name: 'UserList' }}},[_c('h2',{class:{
                'head-users': _vm.section === 'users',
                'menu-users': _vm.section !== 'users',
              }},[_vm._v(" Users ")])])],1)]),_c('nav',{staticClass:"support-menu of-app-menu"},[_c('ul',{staticClass:"of-logout"},[_c('li',{staticClass:"menu-logout"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v("Log out")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"brand-wrap"},[_c('div',{attrs:{"id":"of_logo"}})])
}]

export { render, staticRenderFns }