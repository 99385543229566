import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Dates from "./filters/dates";
import { ValidationProvider } from "vee-validate";

Vue.config.productionTip = false;

new Vue({
  router,
  components: {
    ValidationProvider,
  },
  filters: {
    Dates,
  },
  data: {
    systemEndpoints: [],
    configurableSystems: [],
    availableGateways: [],
    configurableGateways: [],
  },
  render: (h) => h(App),
}).$mount("#app");
