import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Navigation from "@/components/shared/layout/Navigation.vue";
import { format } from "date-fns";

export const eventHub = new Vue();

Vue.use(VueRouter);

Vue.filter("formatDate", (value?: string) => {
  if (value) {
    return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
  }
  return "";
});

Vue.filter("currency", function (value?: null) {
  if (value !== undefined && value !== null) {
    const numberFormat = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "DKK",
    });
    return numberFormat.format(value);
  }
  return "";
});

const routes: Array<RouteConfig> = [
  {
    path: "/app",
    name: "Navigation",
    component: Navigation,
    children: [
      {
        path: "/front",
        name: "Front",
        component: () => import("../components/Front.vue"),
      },
      {
        path: "invoicing",
        name: "Invoicing",
        component: () => import("../components/invoice/Invoices.vue"),
      },
      {
        path: "invoicing/log",
        name: "InvoicingLog",
        component: () => import("../components/invoice/InvoiceLog.vue"),
      },
      {
        path: "invoicing/uninvoiced",
        name: "Uninvoiced",
        component: () => import("../components/invoice/Uninvoiced.vue"),
      },
      {
        path: "notices",
        name: "Notices",
        component: () => import("@/components/notices/Notices.vue"),
      },
      {
        path: "customers",
        name: "CustomerList",
        component: () => import("../components/customers/CustomerList.vue"),
      },
      {
        path: "merchants",
        name: "Merchants",
        component: () => import("../components/merchants/Merchants.vue"),
      },
      {
        path: "products",
        name: "Products",
        component: () => import("../components/products/Products.vue"),
      },
      {
        path: "gateways",
        name: "GatewayList",
        component: () => import("../components/gateways/GatewayList.vue"),
      },
      {
        path: "users",
        name: "UserList",
        component: () => import("../components/users/UserList.vue"),
      },
      {
        path: "endpoints",
        name: "EndpointList",
        component: () => import("../components/endpoints/EndpointList.vue"),
      },
      {
        path: "agencies",
        name: "AgenciesList",
        component: () => import("../components/agencies/AgenciesList.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/",
    redirect: { name: "Merchants" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function isAuthenticated() {
  const token = localStorage.getItem("token");
  if (token) {
    const tokenParts = token.split(".");
    const payloadString = tokenParts[1];
    const jsonPayload: any = atob(payloadString);
    const exp = JSON.parse(jsonPayload).exp;
    const now = new Date().getTime() / 1000;
    if (exp < now) {
      localStorage.removeItem("token");
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
  return !!localStorage.getItem("token");
}

router.beforeEach((to, from, next) => {
  const isNotLoggedIn = to.name !== "Login";
  if (isNotLoggedIn && !isAuthenticated()) next({ name: "Login" });
  else next();
});

export default router;
